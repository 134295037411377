import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import BCrumbs from '../components/BCrumbs'
import circuitsImage from '../assets/images/circuits.jpg'
import HeaderGeneric from '../components/HeaderGeneric'

const Tags = ({ data }) => {
  const { group } = data.allMarkdownRemark
  const tagHeader = `${group.length} tag${group.length === 1 ? '' : 's'}`

  return (
    <Layout>
      <Helmet title="Digital Tumbleweed: Tags" />

      <div id="main">
        <img src={circuitsImage} alt="" className="header" />

        <BCrumbs crumbs={[{ path: '/blog', title: 'Blog' }]} />

        <HeaderGeneric title={tagHeader} />

        <section id="content" className="main">
          <ul>
            {group.map(({ fieldValue }) => {
              return (
                <li key={fieldValue}>
                  <a href={`/blog/tags/${fieldValue}`} title={fieldValue}>
                    {fieldValue}
                  </a>
                </li>
              )
            })}
          </ul>
        </section>
      </div>
    </Layout>
  )
}

export default Tags

export const pageQuery = graphql`
  {
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
      }
    }
  }
`
